import React from "react";
import Layout from "../components/layout";
// import { StaticQuery, graphql } from 'gatsby';

// const query = graphql`
//   query AboutQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;

export default ({ location }) => (
  <Layout location={location}>
    <div>
      <h1>Erreur 404</h1>
      <p>
        Désolé une erreur s'est produite ! Essayez de retrouver votre chemin via
        le menu
      </p>
    </div>
  </Layout>
);
